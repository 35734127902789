<template>
  <v-app class="background-color">
    <Snackbar />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-app>
</template>

<script>
const Snackbar = () => import("@/components/Snackbar");

export default {
  name: "App",
  components: {
    Snackbar
  }
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
