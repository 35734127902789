export const initialPersonalState = () => ({
  NIP: null,
  nama: null,
  presensi_id: null,
  photo: null,
  tempat_lahir: null,
  tanggal_lahir: null,
  usia: null,
  kelamin: null,
  status_diri: null,
  agama: null,
  alamat: null,
  kelurahan: null,
  kecamatan: null,
  kabupaten: null,
  provinsi: null,
  rt_rw: null,
  kode_pos: null,
  pendidikan: {
    jenjang: null,
    bidang_studi: null,
    nama_sekolah: null,
    thn_lulus: null
  },
  lainnya: {
    NPWP: null,
    no_taspen: null,
    hp_number: null,
    email_address: null
  }
});

export const initialEmployeeState = () => ({
  status_peg: null,
  jenis_peg: null,
  mks: null,
  mkg: null,
  cpns: {
    tgl_mulai_CPNS: null,
    no_sk_CPNS: null,
    tgl_sk_CPNS: null
  },
  pns: {
    tgl_mulai_PNS: null,
    no_sk_PNS: null,
    tgl_sk_PNS: null
  },
  no_karpeg: null,
  no_karis_su: null,
  PMK: null,
  jabatan_eselon: {
    jabatan: null,
    eselon: null,
    tmt_jabatan: null,
    no_sk_jabatan: null,
    tgl_sk_jabatan: null
  },
  golongan_pangkat: {
    gol: null,
    pangkat: null,
    tmt_gol: null,
    no_sk_gol: null,
    tgl_sk_gol: null
  },
  status_aktif: null,
  perkiraan_pensiun: null
});

export const initialKeluargaState = () => ({
  pasangan: [],
  anak: [],
  orang_tua: [],
  mertua: [],
  saudara: []
});

export const initialUnitKerjaState = () => ({
  unit_kerja_2: null,
  unit_kerja_3: null,
  unit_kerja_4: null,
  unit_utama: null
});

export const initialRiwayatState = () => ({
  keyang: "R_Angka_Kredit",
  keycut: "R_Cuti",
  keydik: "R_Diklat",
  keygaj: "R_Gaji",
  keygol: "R_Golongan",
  keyjab: "R_Jabatan",
  keykin: "R_Kinerja",
  keykur: "R_Kursus",
  keypen: "R_Pendidikan",
  keytan: "R_Tanda_Jasa",
  lbang: null,
  lbcut: null,
  lbdik: null,
  lbgaj: null,
  lbgol: null,
  lbjab: null,
  lbkin: null,
  lbkur: null,
  lbpen: null,
  lbtan: null
});
