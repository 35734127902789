import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import { configRoutes } from "./routes";
import { configRoutes as configRoutesLanding } from "./landingRoutes";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes:
    process.env.VUE_APP_MODE === "landing"
      ? configRoutesLanding()
      : configRoutes()
});
