import Vue from "vue";
/**
 * Limit a string to short string.
 * Example: description => desc...
 *
 * @param  {String} str the string to limit
 * @return {String}
 */
Vue.filter("str_limit", function(value, size) {
  if (!value) return "";
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "...";
});

Vue.filter("toTitle", str => {
  return (
    str &&
    str
      .split(" ")
      .map(function(item) {
        return item.charAt(0).toUpperCase() + item.substring(1).toLowerCase();
      })
      .join(" ")
  );
});

Vue.filter("snakeToTitle", str => {
  return (
    str &&
    str
      .split("_")
      .map(function(item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
      })
      .join(" ")
  );
});

Vue.filter("snakeToUppercase", str => {
  return (
    str &&
    str
      .split("_")
      .map(function(item) {
        return item.toUpperCase();
      })
      .join(" ")
  );
});

Vue.filter("dashToTitle", str => {
  return (
    str &&
    str
      .split("-")
      .map(function(item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
      })
      .join(" ")
  );
});

Vue.filter("camelToTitle", str => {
  return str
    ? str
        .replace(/([A-Z])/g, match => ` ${match}`)
        .replace(/^./, match => match.toUpperCase())
    : "";
});

Vue.filter("bytesFormat", function(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
});
