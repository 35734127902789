import { getField, updateField } from "vuex-map-fields";
import { initialPegawaiFilter } from "../state/index";

const state = {
  pegawai: initialPegawaiFilter()
};

const getters = {
  getRootField(state) {
    return getField(state);
  }
};

const actions = {};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  updateRootField(state, field) {
    updateField(state, field);
  },
  ["resetPegawaiFilter"](state) {
    Object.assign(state.pegawai, initialPegawaiFilter());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
