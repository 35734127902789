import Vue from "vue";
import router from "../router";
import axios from "axios";
import { mapSummaryRootField } from "@/store/helpers";

// Global Mixin
Vue.mixin({
  computed: {
    ...mapSummaryRootField({
      isPresensi: "setting.isPresensi"
    })
  },
  methods: {
    getToPage(routeName, params, type = "push") {
      router[type]({ name: routeName, ...params });
    },
    fetchListDebounce(callback) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        callback();
      }, 500);
    },
    async downloadTemplate(URL_TEMPLATE, NAME_TEMPLATE) {
      const link = document.createElement("a");
      link.href = URL_TEMPLATE;
      link.target = "_blank";
      link.setAttribute("download", NAME_TEMPLATE);
      document.body.appendChild(link);
      link.click();
    },
    getDocumentBlob(URL) {
      return axios
        .get(URL, {
          responseType: "arraybuffer"
        })
        .then(response =>
          Buffer.from(response.data, "binary").toString("base64")
        );
    }
  }
});
