// Containers
const TheContainer = () => import("@/containers");

// Page
const Profile = () => import("@/views/Profile");
const ProfileChangePassword = () => import("@/views/Profile/change_password");
const Dashboard = () => import("@/views/Dashboard");
const DataPegawai = () => import("@/views/DataPegawai");
const LaporanDataPegawai = () => import("@/views/LaporanDataPegawai");
const RekapitulasiDataPegawai = () => import("@/views/RekapitulasiDataPegawai");
const DataPresensi = () => import("@/views/DataPresensi");
const DataPegawaiCreate = () => import("@/views/DataPegawai/Create");
const DataPegawaiDetail = () => import("@/views/DataPegawai/detail");
const DataPresensiDetail = () => import("@/views/DataPresensi/detail");
const DataPerubahanPegawai = () =>
  import("@/views/PerubahanData/DataPerubahanPegawai");
const DataPerubahanPegawaiDetail = () =>
  import("@/views/PerubahanData/DataPerubahanPegawai/detail");
const VerifikasiPerubahan = () =>
  import("@/views/PerubahanData/VerifikasiPerubahan");
const VerifikasiPerubahanCreate = () =>
  import("@/views/PerubahanData/VerifikasiPerubahan/Create");
const CapaianKinerja = () => import("@/views/CapaianKinerja");
const PendingCatatan = () => import("@/views/ManajemenCatatan/PendingCatatan");
const HistoryCatatan = () => import("@/views/ManajemenCatatan/HistoryCatatan");
const PengajuanCatatan = () =>
  import("@/views/ManajemenCatatan/PengajuanCatatan");
const PenjadwalanUnit = () => import("@/views/Penjadwalan/PenjadwalanUnit");
const PenjadwalanLibur = () => import("@/views/Penjadwalan/PenjadwalanLibur");
const PenjadwalanPeriod = () => import("@/views/Penjadwalan/PenjadwalanPeriod");
const PenjadwalanManual = () => import("@/views/Penjadwalan/PenjadwalanManual");
const PenjadwalanGroupNIP = () =>
  import("@/views/Penjadwalan/PenjadwalanGroupNIP");
const KedisiplinanPegawai = () =>
  import("@/views/ManajemenLaporan/KedisiplinanPegawai");
const DetailKehadiran = () =>
  import("@/views/ManajemenLaporan/DetailKehadiran");
const RekapitulasiKehadiran = () =>
  import("@/views/ManajemenLaporan/RekapitulasiKehadiran");
const RekapitulasiCuti = () =>
  import("@/views/ManajemenLaporan/RekapitulasiCuti");
const Lampiran1G = () => import("@/views/ManajemenLaporan/Lampiran1G");
const Rekapitulasi = () => import("@/views/Rekapitulasi");
const PengaturanMesinAbsen = () => import("@/views/PengaturanMesinAbsen");
const PengaturanFingerID = () => import("@/views/PengaturanFingerID");
const Disiplin = () => import("@/views/Disiplin");
const Kepangkatan = () => import("@/views/Kepangkatan");
const PengaturanAdmin = () => import("@/views/PengaturanAdmin");
const PengaturanProfileAdmin = () =>
  import("@/views/PengaturanAdmin/ProfileAdmin");
const ConfigPersenTukin = () => import("@/views/Config/PersenTukin");

const DataUnitUtama = () => import("@/views/DataUtama/UnitUtama");
const DataUnitGroup = () => import("@/views/DataUtama/UnitGroup");
const DataUnitKerja2 = () => import("@/views/DataUtama/UnitKerja2");
const DataUnitKerja3 = () => import("@/views/DataUtama/UnitKerja3");
const DataUnitKerja4 = () => import("@/views/DataUtama/UnitKerja4");
const DataLokasi = () => import("@/views/DataUtama/DataLokasi");
const DataNomenklatur = () => import("@/views/DataUtama/unit");
const DataGolongan = () => import("@/views/DataUtama/golongan");
const DataKepangkatan = () => import("@/views/DataUtama/kepangkatan");
const DataEselon = () => import("@/views/DataUtama/eselon");
const DataPendidikan = () => import("@/views/DataUtama/Pendidikan/index");
const DataPendidikanLevel = () => import("@/views/DataUtama/pendidikanLevel");
const DataPendidikanRumpun = () => import("@/views/DataUtama/pendidikanRumpun");
const DataKelasJabatan = () => import("@/views/DataUtama/kelasJabatan");
const DataJabatan = () => import("@/views/DataUtama/jabatan");
const DataKelompokJabatan = () => import("@/views/DataUtama/kelompokJabatan");
const DataFormasiJabatan = () => import("@/views/DataUtama/formasiJabatan");
const DataDigital = () => import("@/views/DataUtama/DataDigital");
const PemetaanGolongan = () => import("@/views/DataUtama/PemetaanGolongan");
const Berita = () => import("@/views/Berita");
const Notification = () => import("@/views/Notification");
const Pesan = () => import("@/views/Pesan");
const About = () => import("@/views/About.vue");
const Login = () => import("@/views/Auth/Login.vue");
const ForgotPassword = () => import("@/views/Auth/ForgotPassword.vue");
const ResetPassword = () => import("@/views/Auth/ResetPassword.vue");
const Page404 = () => import("@/views/Page404.vue");
const Page500 = () => import("@/views/Page500.vue");
const Page503 = () => import("@/views/Page503.vue");

export const configRoutes = () => {
  return [
    {
      path: "/",
      component: TheContainer,
      children: [
        {
          path: "",
          name: "home",
          component: Dashboard,
          meta: {
            requiresAuth: true,
            title: "Dasboard"
          }
        },
        {
          path: "profile",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "profile",
              component: Profile,
              meta: {
                requiresAuth: true,
                title: "Profile"
              }
            },
            {
              path: "change-password",
              name: "profile.change.password",
              component: ProfileChangePassword,
              meta: {
                requiresAuth: true,
                title: "Profile Change Password"
              }
            }
          ]
        },
        {
          path: "data-pegawai",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "employee",
              component: DataPegawai,
              meta: {
                requiresAuth: true,
                title: "Data Pegawai"
              }
            },
            {
              path: "create",
              name: "employee.create",
              component: DataPegawaiCreate,
              meta: {
                requiresAuth: true,
                title: "Tambah Data Pegawai"
              }
            },
            {
              path: ":employeeId",
              name: "employee.detail",
              component: DataPegawaiDetail,
              meta: {
                requiresAuth: true,
                title: "Detail Data Pegawai"
              }
            }
          ]
        },
        {
          path: "laporan-data-pegawai",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "employee-report",
              component: LaporanDataPegawai,
              meta: {
                requiresAuth: true,
                title: "Laporan Data Pegawai"
              }
            }
          ]
        },
        {
          path: "rekapitulasi-data-pegawai",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "employee-recap",
              component: RekapitulasiDataPegawai,
              meta: {
                requiresAuth: true,
                title: "Rekapitulasi Data Pegawai"
              }
            }
          ]
        },
        {
          path: "data-presensi",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "presensi",
              component: DataPresensi,
              meta: {
                requiresAuth: true,
                title: "Data Presensi"
              }
            },
            {
              path: ":employeeId",
              name: "presensi.detail",
              component: DataPresensiDetail,
              meta: {
                requiresAuth: true,
                title: "Detail Data Presensi"
              }
            }
          ]
        },
        {
          path: "perubahan-pegawai",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "employee-updated",
              component: DataPerubahanPegawai,
              meta: {
                requiresAuth: true,
                title: "Data Perubahan Pegawai"
              }
            },
            {
              path: ":historyId",
              name: "employee-updated.detail",
              component: DataPerubahanPegawaiDetail,
              meta: {
                requiresAuth: true,
                title: "Detail Data Perubahan Pegawai"
              }
            }
          ]
        },
        {
          path: "verifikasi-perubahan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "verification-update",
              component: VerifikasiPerubahan,
              meta: {
                requiresAuth: true,
                title: "Verifikasi Perubahan Data"
              }
            },
            {
              path: ":submissionId",
              name: "verification-update.create",
              component: VerifikasiPerubahanCreate,
              meta: {
                requiresAuth: true,
                title: "Verifikasi Detail Perubahan Data"
              }
            }
          ]
        },
        {
          path: "penjadwalan-unit",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "penjadwalan-unit",
              component: PenjadwalanUnit,
              meta: {
                requiresAuth: true,
                title: "Penjadwalan Unit"
              }
            }
          ]
        },
        {
          path: "penjadwalan-libur",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "penjadwalan-libur",
              component: PenjadwalanLibur,
              meta: {
                requiresAuth: true,
                title: "Penjadwalan Libur"
              }
            }
          ]
        },
        {
          path: "penjadwalan-period",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "penjadwalan-period",
              component: PenjadwalanPeriod,
              meta: {
                requiresAuth: true,
                title: "Penjadwalan Period"
              }
            }
          ]
        },
        {
          path: "penjadwalan-manual",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "penjadwalan-manual",
              component: PenjadwalanManual,
              meta: {
                requiresAuth: true,
                title: "Penjadwalan Manual"
              }
            }
          ]
        },
        {
          path: "penjadwalan-group-nip",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "penjadwalan-group-nip",
              component: PenjadwalanGroupNIP,
              meta: {
                requiresAuth: true,
                title: "Penjadwalan Group NIP"
              }
            }
          ]
        },
        {
          path: "kedisiplinan-pegawai",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "kedisiplinan-pegawai",
              component: KedisiplinanPegawai,
              meta: {
                requiresAuth: true,
                title: "Kedisiplinan Pegawai"
              }
            }
          ]
        },
        {
          path: "detail-kehadiran",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "detail-kehadiran",
              component: DetailKehadiran,
              meta: {
                requiresAuth: true,
                title: "Detail Kehadiran"
              }
            }
          ]
        },
        {
          path: "rekapitulasi-kehadiran",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "rekapitulasi-kehadiran",
              component: RekapitulasiKehadiran,
              meta: {
                requiresAuth: true,
                title: "Rekapitulasi Kehadiran"
              }
            }
          ]
        },
        {
          path: "rekapitulasi-cuti",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "rekapitulasi-cuti",
              component: RekapitulasiCuti,
              meta: {
                requiresAuth: true,
                title: "Rekapitulasi Cuti"
              }
            }
          ]
        },
        {
          path: "lampiran-1g",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "lampiran-1g",
              component: Lampiran1G,
              meta: {
                requiresAuth: true,
                title: "Lampiran 1G"
              }
            }
          ]
        },
        {
          path: "capaian-kinerja-bulanan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "performance-achievement",
              component: CapaianKinerja,
              meta: {
                requiresAuth: true,
                title: "Capaian Kinerja"
              }
            }
          ]
        },
        {
          path: "pengajuan-catatan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "notes-submission",
              component: PengajuanCatatan,
              meta: {
                requiresAuth: true,
                title: "Pengajuan Catatan"
              }
            }
          ]
        },
        {
          path: "history-catatan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "notes-history",
              component: HistoryCatatan,
              meta: {
                requiresAuth: true,
                title: "History Catatan"
              }
            }
          ]
        },
        {
          path: "pending-catatan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "notes-pending",
              component: PendingCatatan,
              meta: {
                requiresAuth: true,
                title: "Pending Catatan"
              }
            }
          ]
        },
        {
          path: "rekapitulasi",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "summary",
              component: Rekapitulasi,
              meta: {
                requiresAuth: true,
                title: "Rekapitulasi"
              }
            }
          ]
        },
        {
          path: "pengaturan-mesin-absen",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "machine-setting",
              component: PengaturanMesinAbsen,
              meta: {
                requiresAuth: true,
                title: "Pengaturan Mesin Absen"
              }
            }
          ]
        },
        {
          path: "pengaturan-finger-id",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "finger-id-setting",
              component: PengaturanFingerID,
              meta: {
                requiresAuth: true,
                title: "Pengaturan Finger ID"
              }
            }
          ]
        },
        {
          path: "disiplin",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "discipline",
              component: Disiplin,
              meta: {
                requiresAuth: true,
                title: "Disiplin"
              }
            }
          ]
        },
        {
          path: "kepangkatan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "rank",
              component: Kepangkatan,
              meta: {
                requiresAuth: true,
                title: "Kepangkatan"
              }
            }
          ]
        },
        {
          path: "pengaturan-admin",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "admin-setting",
              component: PengaturanAdmin,
              meta: {
                requiresAuth: true,
                title: "Pengaturan Admin"
              }
            },
            {
              path: "",
              name: "profile-admin",
              component: PengaturanProfileAdmin,
              meta: {
                requiresAuth: true,
                title: "Profile Admin"
              }
            }
          ]
        },
        {
          path: "data-nomenklatur",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-nomenklatur",
              component: DataNomenklatur,
              meta: {
                requiresAuth: true,
                title: "Data Nomenklatur"
              }
            }
          ]
        },
        {
          path: "data-unit-utama",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-unit-utama",
              component: DataUnitUtama,
              meta: {
                requiresAuth: true,
                title: "Data Unit Utama"
              }
            }
          ]
        },
        {
          path: "data-unit-group",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-unit-group",
              component: DataUnitGroup,
              meta: {
                requiresAuth: true,
                title: "Data Unit Group"
              }
            }
          ]
        },
        {
          path: "data-unit-kerja-2",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-unit-kerja-2",
              component: DataUnitKerja2,
              meta: {
                requiresAuth: true,
                title: "Data Unit Kerja 2"
              }
            }
          ]
        },
        {
          path: "data-unit-kerja-3",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-unit-kerja-3",
              component: DataUnitKerja3,
              meta: {
                requiresAuth: true,
                title: "Data Unit Kerja 3"
              }
            }
          ]
        },
        {
          path: "data-unit-kerja-4",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-unit-kerja-4",
              component: DataUnitKerja4,
              meta: {
                requiresAuth: true,
                title: "Data Unit Kerja 4"
              }
            }
          ]
        },
        {
          path: "data-lokasi",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-lokasi",
              component: DataLokasi,
              meta: {
                requiresAuth: true,
                title: "Data Lokasi"
              }
            }
          ]
        },
        {
          path: "data-golongan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-golongan",
              component: DataGolongan,
              meta: {
                requiresAuth: true,
                title: "Data Golongan"
              }
            }
          ]
        },
        {
          path: "data-kepangkatan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-kepangkatan",
              component: DataKepangkatan,
              meta: {
                requiresAuth: true,
                title: "Data Kepangkatan"
              }
            }
          ]
        },
        {
          path: "data-eselon",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-eselon",
              component: DataEselon,
              meta: {
                requiresAuth: true,
                title: "Data Eselon"
              }
            }
          ]
        },
        {
          path: "data-pendidikan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-pendidikan",
              component: DataPendidikan,
              meta: {
                requiresAuth: true,
                title: "Data Pendidikan"
              }
            }
          ]
        },
        {
          path: "data-pendidikan-level",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-pendidikan-level",
              component: DataPendidikanLevel,
              meta: {
                requiresAuth: true,
                title: "Data Pendidikan Level"
              }
            }
          ]
        },
        {
          path: "data-pendidikan-rumpun",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-pendidikan-rumpun",
              component: DataPendidikanRumpun,
              meta: {
                requiresAuth: true,
                title: "Data Pendidikan Rumpun"
              }
            }
          ]
        },
        {
          path: "data-kelas-jabatan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-kelas-jabatan",
              component: DataKelasJabatan,
              meta: {
                requiresAuth: true,
                title: "Data Kelas Jabatan"
              }
            }
          ]
        },
        {
          path: "data-jabatan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-jabatan",
              component: DataJabatan,
              meta: {
                requiresAuth: true,
                title: "Data Jabatan"
              }
            }
          ]
        },
        {
          path: "data-kelompok-jabatan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-kelompok-jabatan",
              component: DataKelompokJabatan,
              meta: {
                requiresAuth: true,
                title: "Data Kelompok Jabatan"
              }
            }
          ]
        },
        {
          path: "data-formasi-jabatan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-formasi-jabatan",
              component: DataFormasiJabatan,
              meta: {
                requiresAuth: true,
                title: "Data Formasi Jabatan"
              }
            }
          ]
        },
        {
          path: "data-digital",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "data-digital",
              component: DataDigital,
              meta: {
                requiresAuth: true,
                title: "Data Digital"
              }
            }
          ]
        },
        {
          path: "pemetaan-golongan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "pemetaan-golongan",
              component: PemetaanGolongan,
              meta: {
                requiresAuth: true,
                title: "Pemetaan Golongan"
              }
            }
          ]
        },
        {
          path: "config-persen-tukin",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "config-persen-tukin",
              component: ConfigPersenTukin,
              meta: {
                requiresAuth: true,
                title: "Config Persen Tukin"
              }
            }
          ]
        },
        {
          path: "berita",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "news",
              component: Berita,
              meta: {
                requiresAuth: true,
                title: "Berita"
              }
            }
          ]
        },
        {
          path: "notification",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "notification",
              component: Notification,
              meta: {
                requiresAuth: true,
                title: "Notifikasi"
              }
            }
          ]
        },
        {
          path: "pesan",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "chat",
              component: Pesan,
              meta: {
                requiresAuth: true,
                title: "Pesan"
              }
            }
          ]
        },
        {
          path: "about",
          name: "about",
          component: About,
          meta: {
            requiresAuth: true,
            title: "About"
          }
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/forgot-password",
      name: "forgot.password",
      component: ForgotPassword
    },
    {
      path: "/reset-password",
      name: "reset.password",
      component: ResetPassword
    },
    {
      path: "/404",
      name: "page.404",
      component: Page404
    },
    {
      path: "/500",
      name: "page.500",
      component: Page500
    },
    {
      path: "/503",
      name: "page.503",
      component: Page503
    }
  ];
};
