import { ID_TOKEN_KEY } from "./constants";

const get = key => {
  return window.localStorage.getItem(key);
};

const set = (key, data) => {
  window.localStorage.setItem(key, data);
};

const remove = key => {
  window.localStorage.removeItem(key);
};

// Token
export const getToken = () => {
  return get(ID_TOKEN_KEY);
};

export const saveToken = data => {
  set(ID_TOKEN_KEY, data);
};

export const destroyToken = () => {
  remove(ID_TOKEN_KEY);
};

export default {
  getToken,
  saveToken,
  destroyToken
};
