import { getField, updateField } from "vuex-map-fields";
import {
  initialPersonalState,
  initialEmployeeState,
  initialKeluargaState,
  initialUnitKerjaState,
  initialRiwayatState
} from "../state/index";

const state = {
  personal: initialPersonalState(),
  employee: initialEmployeeState(),
  keluarga: initialKeluargaState(),
  unit_kerja: initialUnitKerjaState(),
  riwayat: initialRiwayatState()
};

const getters = {
  getEmployeeRootField(state) {
    return getField(state);
  },
  getPersonalField(state) {
    return getField(state.personal);
  },
  getEmployeeField(state) {
    return getField(state.employee);
  },
  getKeluargaField(state) {
    return getField(state.keluarga);
  },
  getUnitKerjaField(state) {
    return getField(state.unit_kerja);
  },
  getRiwayatField(state) {
    return getField(state.riwayat);
  }
};

const actions = {};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  updateEmployeeRootField(state, field) {
    updateField(state, field);
  },
  updatePersonalField(state, field) {
    updateField(state.personal, field);
  },
  updateEmployeeField(state, field) {
    updateField(state.employee, field);
  },
  updateKeluargaField(state, field) {
    updateField(state.keluarga, field);
  },
  updateUnitKerjaField(state, field) {
    updateField(state.unit_kerja, field);
  },
  updateRiwayatField(state, field) {
    updateField(state.riwayat, field);
  },
  ["resetEmployeeState"](state) {
    Object.assign(state.personal, initialPersonalState());
    Object.assign(state.employee, initialEmployeeState());
    Object.assign(state.keluarga, initialKeluargaState());
    Object.assign(state.unit_kerja, initialUnitKerjaState());
    Object.assign(state.riwayat, initialRiwayatState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
