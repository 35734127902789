import { DRAWER_COLLAPSED } from "../constants/actions.type";
import {
  SET_DRAWER_COLLAPSED,
  TOGGLE_DRAWER_COLLAPSED
} from "../constants/mutations.type";

const initialState = () => ({
  drawerCollapsed: true
});

const state = initialState();

const getters = {
  drawerCollapsed(state) {
    return state.drawerCollapsed;
  }
};

const actions = {
  [DRAWER_COLLAPSED](context) {
    context.commit(TOGGLE_DRAWER_COLLAPSED);
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_DRAWER_COLLAPSED](state, data) {
    state.drawerCollapsed = data;
  },
  [TOGGLE_DRAWER_COLLAPSED](state) {
    state.drawerCollapsed = !state.drawerCollapsed;
  }
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
};
