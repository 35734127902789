import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";

// Prototype
import moment from "moment";
import _ from "lodash";
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
Object.defineProperty(Vue.prototype, "$_", { value: _ });
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);

// Config
import "./config/fusion-chart.config";

// Filter
import "./filter/date.filter";
import "./filter/currency.filter";
import "./filter/text.filter";

// Mixin
import "./mixin";

// Component
import DialogConfirm from "./components/Dialog/Confirm";
Vue.use(DialogConfirm);

Vue.config.productionTip = false;

store.commit(
  "summary/setIsPresensi",
  process.env.VUE_APP_MODE == "presensi" ? true : false
);

router.beforeEach((to, _, next) => {
  if (to.name == null) {
    next({ name: "home" });
  }

  // Authentication
  if (to.meta.requiresAuth) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters["auth/isAuthenticated"]) {
      store.commit("auth/purgeAuth");
      const loginpath = window.location.pathname;
      const loginsearch = window.location.search;
      next({
        name: "login",
        query: { pathname: loginpath, search: loginsearch }
      });
      setTimeout(function() {
        store.commit("snackbar/setSnack", {
          show: true,
          message: "Token Expired. Sorry you must login again",
          color: "error"
        });
      }, 500);
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
