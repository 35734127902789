import JwtService from "@/common/JwtService";
import { getField, updateField } from "vuex-map-fields";
import { initialProfileState } from "../state/index";

const state = {
  user: {},
  profile: initialProfileState(),
  isAuthenticated: !!JwtService.getToken(),
  token: JwtService.getToken()
};
const getters = {
  getRootField(state) {
    return getField(state);
  },
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  parsedJWT(state) {
    if (state.token) {
      var jwtData = JwtService.parseJwt(state.token);
      jwtData.acronymName = jwtData.full_name
        ? jwtData.full_name
            .match(/\b(\w)/g)
            .join("")
            .substring(0, 2)
        : "Anonymous";
      return jwtData;
    }
    return null;
  }
};

const actions = {};

const mutations = {
  updateRootField(state, field) {
    updateField(state, field);
  },
  setAuth(state, data) {
    state.isAuthenticated = true;
    state.user = data;
    state.token = data.token;
    JwtService.saveToken(data.token);
  },
  purgeAuth(state) {
    state.isAuthenticated = false;
    state.token = null;
    state.user = {};
    JwtService.destroyToken();
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
