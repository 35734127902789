export const initialPegawaiState = () => ({
  NIP: null,
  name: null,
  photo: null,
  unit_utama: null,
  unit_kerja_2: null,
  unit_kerja_3: null,
  unit_kerja_4: null,
  kelas_jabatan: null,
  schedule_group_id: null,
  schedule_group: null
});

export const initialKinerjaState = () => ({
  nilai_tahunan: null,
  nilai_bulanan: null,
  tunjangan_kinerja: null,
  tunjangan_capaian_kinerja: null,
  tunjangan_capaian_kehadiran: null,
  tunjangan_kinerja_dibayarkan: null
});

export const initialKehadiranState = () => ({
  tidak_hadir: null,
  total_denda: null,
  total_waktu_kurang_hadir: null,
  total_waktu_kurang_keberadaan: null,
  akumulasi_kurang_hadir: null,
  akumulasi_kurang_keberadaan: null,
  total_denda_potongan: null
});

export const initialSettingState = () => ({
  isPresensi: false
});

export const initialHistoryAttendancesState = () => ({
  historyAttendances: []
});
