import { getField, updateField } from "vuex-map-fields";
import {
  initialPegawaiState,
  initialKinerjaState,
  initialKehadiranState,
  initialSettingState,
  initialHistoryAttendancesState
} from "../state/index";

const state = {
  pegawai: initialPegawaiState(),
  kinerja: initialKinerjaState(),
  kehadiran: initialKehadiranState(),
  setting: initialSettingState(),
  historyAttendances: initialHistoryAttendancesState()
};

const getters = {
  getRootField(state) {
    return getField(state);
  }
};

const actions = {};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  updateRootField(state, field) {
    updateField(state, field);
  },
  ["setIsPresensi"](state, data) {
    state.setting.isPresensi = data;
  },
  ["resetPegawaiSummary"](state) {
    Object.assign(state.pegawai, initialPegawaiState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
