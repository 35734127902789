import Vue from "vue";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");
/**
 * Converts a epoch timestamp to date format.
 * Example: 1591747200000 => 10 Jun 2020 00:00:00
 *
 * @param  {String} str the string to convert
 * @return {String}
 */

Vue.filter("date", str => {
  return str ? moment(new Date(str)).format("DD MMMM YYYY") : "";
});

Vue.filter("dateShort", str => {
  return str ? moment(new Date(str)).format("DD MMM YYYY") : "";
});

Vue.filter("dateTime", str => {
  return str ? moment(new Date(str)).format("DD MMMM YYYY HH:mm:ss") : "";
});

Vue.filter("dateHour", str => {
  return str ? moment(new Date(str)).format("DD-MM-YYYY HH:mm") : "";
});

Vue.filter("dateLLLL", str => {
  return str ? moment(new Date(str)).format("LLLL") : "-";
});

Vue.filter("dateInput", str => {
  return str ? new Date(str).toISOString().substr(0, 10) : "";
});

Vue.filter("time", str => {
  return str ? moment(str, "HH:mm:ss").format("hh:mm A") : "";
});

Vue.filter("dateMonthDisplay", str => {
  return str ? moment(new Date(str)).format("MMMM") : "";
});

Vue.filter("dateMonthShortDisplay", str => {
  return str ? moment(new Date(str)).format("MMM") : "";
});

Vue.filter("dateDay", str => {
  return str ? moment(new Date(str)).format("DD") : "";
});

Vue.filter("dateMonth", str => {
  return str ? moment(new Date(str)).format("MM") : "";
});

Vue.filter("dateYear", str => {
  return str ? moment(new Date(str)).format("YYYY") : "";
});

Vue.filter("dateDayDisplay", str => {
  return str ? moment(new Date(str)).format("dddd") : "";
});

Vue.filter("dateDisplay", str => {
  return str ? moment(new Date(str)).format("dddd, DD MMMM YYYY") : "";
});
