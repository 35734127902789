export const initialProfileState = () => ({
  NIP: null,
  id: null,
  nama_unit_kerja_2: null,
  nama_unit_kerja_3: null,
  nama_unit_kerja_4: null,
  nama_unit_utama: null,
  name: null,
  photo: null,
  role: null,
  role_name: null,
  unit_kerja_2_id: null,
  unit_kerja_3_id: null,
  unit_kerja_4_id: null,
  unit_utama_id: null,
  unread_message: null,
  unread_notification: null
});
