import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import VuexPersistence from "vuex-persist";

import settings from "./modules/settings.store";
import snackbar from "./modules/snackbar.store";
import auth from "./modules/auth.store";
import employee from "./modules/employee.store";
import summary from "./modules/summary.store";
import filter from "./modules/filter.store";

const debug = process.env.VUE_APP_DEBUG;
const debugPlugin = debug === "true" ? [createLogger({})] : [];

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: "persist-store",
  storage: window.localStorage,
  reducer(val) {
    if (val.auth.token === null) {
      return {};
    }
    return val;
  }
});

export default new Vuex.Store({
  modules: {
    settings,
    snackbar,
    auth,
    employee,
    summary,
    filter
  },
  strict: debug,
  plugins: [vuexLocal.plugin, ...debugPlugin]
});
